class IM_Cards_Filter {
    /** @var {HTMLElement} */
    #$buttons_wrap;

    /** @var {HTMLElement} */
    #$cards_wrap;


    /**
     * @param {HTMLElement} $buttons_wrap
     * @param {HTMLElement} $cards_wrap
     */
    constructor($buttons_wrap, $cards_wrap) {
        this.#$buttons_wrap = $buttons_wrap;
        this.#$cards_wrap   = $cards_wrap;
    }

    initialize() {
        const $buttons = this.#$buttons_wrap.querySelectorAll("[data-filter]");

        for (const $button of $buttons) {
            $button.addEventListener("click", this.on_button_click.bind(this));
        }
    }

    on_button_click(event) {
        const $button = event.target;
        const filter  = $button.getAttribute("data-filter");

        if (filter) {
            this.set_filter(filter);
        }
    }

    /**
     * @param {string} filter
     */
    set_filter(filter) {
        // Set buttons activity.
        const $curr_button = this.#$buttons_wrap.querySelector(".-active[data-filter]");
        const $new_button  = this.#$buttons_wrap.querySelector("[data-filter='" + filter + "']");

        if ($curr_button) {
            $curr_button.classList.remove("-active");
        }

        if ($new_button) {
            $new_button.classList.add("-active");
        }

        // Build array of cards to how and hide.
        const $cards_to_show = [];
        const $cards_to_hide = [];

        const $cards = this.#$cards_wrap.querySelectorAll("[data-filter]");

        for (const $card of $cards) {
            const card_is_visible = (!$card.classList.contains("-hidden"));
            let card_should_be_visible;

            if (filter === "all") {
                card_should_be_visible = true;
            } else {
                let card_filters;
                card_filters = $card.getAttribute("data-filter");
                card_filters = (typeof card_filters === "string") ? card_filters : "";
                card_filters = card_filters.split(",");
                card_filters = card_filters.map((element) => element.trim());
                card_filters = card_filters.filter((element) => element.length > 0);

                card_should_be_visible = card_filters.includes(filter);
            }

            if (card_should_be_visible && !card_is_visible) {
                $cards_to_show.push($card);
            } else if (!card_should_be_visible && card_is_visible) {
                $cards_to_hide.push($card);
            }
        }

        // Show and hide cards.
        for (const $card of $cards_to_show) {
            $card.style.display = "block";

            setTimeout(() => {
                $card.classList.remove("-hidden");
            }, 25);
        }

        for (const $card of $cards_to_hide) {
            $card.classList.add("-hidden");

            setTimeout(() => {
                $card.style.display = "none";
            }, 150);
        }
    }
}


// Initialize.
(() => {
    const $widgets = document.querySelectorAll(".im-jobs");

    for (const $widget of $widgets) {
        const $buttons_wrap = $widget.querySelector(".im-jobs-categories");
        const $cards_wrap   = $widget.querySelector(".im-jobs-cards");

        if ($buttons_wrap && $cards_wrap) {
            $widget.im_filter = new IM_Cards_Filter($buttons_wrap, $cards_wrap);
            $widget.im_filter.initialize();
        }
    }
})();
